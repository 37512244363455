// data

const roomsData = {
    basement: {
        name: 'sous-sol',
        id: 'basement',
        next: ['foyer', 'diningRoom', 'library'],
        shoot: ['basement', 'foyer', 'diningRoom'],
        shootFrom: ['basement', 'diningRoom'],
        action: {
            pa: 1,
            name: 'panneau de contrôle',
            effect: "Je place un piège dans n'importe quelle autre pièce. Ce piège est activé automatiquement et gratuitement au début de mon prochain tour.",
        },
        blueprint: {
            top: 79,
            left: 18,
            width: 62,
            height: 13,
        },
        icons: {
            move: {
                top: 36,
                left: 19,
            },
            shoot: {
                top: 23,
                left: 48,
            },
            step: {
                top: 36,
                left: 19,
            },
            trap: {
                top: 53,
                left: 73,
            },
        },
    },
    balcony: {
        name: 'balcon',
        id: 'balcony',
        next: ['hall', 'foyer'],
        shoot: ['balcony', 'hall', 'foyer', 'kitchen', 'diningRoom'],
        shootFrom: ['balcony', 'bedroom', 'hall', 'library'],
        action: {
            pa: 0,
            name: 'sauter par-dessus la ballustrade',
            effect: "Je me déplace directement à la cuisine.",
        },
        blueprint: {
            top: 35,
            left: 34,
            width: 34,
            height: 10,
        },
        icons: {
            move: {
                top: 37,
                left: 8,
            },
            shoot: {
                top: 71,
                left: 55,
            },
            step: {
                top: 37,
                left: 8,
            },
            trap: {
                top: 9,
                left: 81,
            },
        },
    },
    bedroom: {
        name: 'chambre',
        id: 'bedroom',
        next: ['hall', 'kitchen'],
        shoot: ['balcony', 'bedroom', 'hall'],
        shootFrom: ['bedroom', 'hall'],
        action: {
            pa: 1,
            name: "levier d'innondation",
            effect: "Si tu te trouves dans le sous-sol, tu es touché une fois et doit te déplacer immédiatement. Le sous-sol est inaccessible jusqu'à mon prochain tour.",
        },
        blueprint: {
            top: 21,
            left: 19,
            width: 23,
            height: 13,
        },
        icons: {
            move: {
                top: 61,
                left: 54,
            },
            shoot: {
                top: 22,
                left: 74,
            },
            step: {
                top: 61,
                left: 54,
            },
            trap: {
                top: 7,
                left: 5,
            },
        },
    },
    diningRoom: {
        name: 'salle à manger',
        id: 'diningRoom',
        next: ['foyer', 'kitchen', 'basement'],
        shoot: ['diningRoom', 'foyer', 'kitchen'],
        shootFrom: ['diningRoom', 'balcony', 'basement', 'foyer', 'kitchen'],
        blueprint: {
            top: 58,
            left: 19,
            width: 17,
            height: 18,
        },
        icons: {
            move: {
                top: 10,
                left: 28,
            },
            shoot: {
                top: 78,
                left: 13,
            },
            step: {
                top: 10,
                left: 28,
            },
            trap: {
                top: 69,
                left: 63,
            },
        },
    },
    foyer: {
        name: 'foyer',
        id: 'foyer',
        next: ['balcony', 'basement', 'kitchen', 'diningRoom'],
        shoot: ['foyer', 'diningRoom', 'kitchen'],
        shootFrom: ['foyer', 'balcony', 'basement', 'diningRoom', 'kitchen'],
        action: {
            pa: 0,
            name: 'échos',
            effect: "J'écoute gratuitement. Si tu es au deuxième étage, tu dois révéler to emplacement exact.",
        },
        blueprint: {
            top: 58,
            left: 37,
            width: 33,
            height: 18,
        },
        icons: {
            move: {
                top: 37,
                left: 9,
            },
            shoot: {
                top: 79,
                left: 17,
            },
            step: {
                top: 37,
                left: 9,
            },
            trap: {
                top: 66,
                left: 75,
            },
        },
    },
    hall: {
        name: 'hall',
        id: 'hall',
        next: ['balcony', 'bedroom', 'library'],
        shoot: ['balcony', 'bedroom', 'hall', 'library'],
        shootFrom: ['hall', 'balcony', 'bedroom', 'library'],
        blueprint: {
            top: 21,
            left: 44,
            width: 13,
            height: 13,
        },
        icons: {
            move: {
                top: 64,
                left: 54,
            },
            shoot: {
                top: 69,
                left: 16,
            },
            step: {
                top: 64,
                left: 54,
            },
            trap: {
                top: 7,
                left: 33,
            },
        },
    },
    kitchen: {
        name: 'cuisine',
        id: 'kitchen',
        next: ['bedroom', 'diningRoom', 'foyer'],
        shoot: ['kitchen', 'diningRoom', 'foyer'],
        shootFrom: ['kitchen', 'balcony', 'diningRoom', 'foyer'],
        action: {
            pa: 0,
            name: 'revigoré',
            effect: "Si je commence mon tour dans la cuisine, j'ai 3 PA lors de ce tour.",
        },
        blueprint: {
            top: 46,
            left: 19,
            width: 28,
            height: 11,
        },
        icons: {
            move: {
                top: 13,
                left: 74,
            },
            shoot: {
                top: 25,
                left: 44,
            },
            step: {
                top: 13,
                left: 74,
            },
            trap: {
                top: 61,
                left: 3,
            },
        },
    },
    library: {
        name: 'bibliothèque',
        id: 'library',
        next: ['hall', 'basement'],
        shoot: ['hall', 'library', 'balcony'],
        shootFrom: ['library', 'hall'],
        action: {
            pa: 1,
            name: 'interrupteur de la trappe',
            effect: "Si tu es dans la cuisine tu te déplaces immédiatement au sous-sol. Si le sous-sol est innondé, tu es touché une fois et te déplace encore.",
        },
        blueprint: {
            top: 21,
            left: 58,
            width: 23,
            height: 13,
        },
        icons: {
            move: {
                top: 62,
                left: 28,
            },
            shoot: {
                top: 21,
                left: 69,
            },
            step: {
                top: 62,
                left: 28,
            },
            trap: {
                top: 6,
                left: 5,
            },
        },
    },
}

let isVisible = true

// UI elements

const gameEl = document.getElementById('game')
const informationsEl = document.getElementById('informations')
const resetButton = document.getElementById('action-reset')
const setTrapButton = document.getElementById('action-set-trap')
const triggerTrapButton = document.getElementById('action-trigger-trap')
const roomsEl = document.getElementById('rooms')
const roomTemplate = document.getElementById('room-template')

// helpers

const dispatch = (name, data) => gameEl.dispatchEvent(new CustomEvent(name, { detail: data }))
const listen = (name, fn) => gameEl.addEventListener(name, ({ detail }) => fn(detail))
const addClass = name => el => el.classList.add(name)
const removeClass = name => el => el.classList.remove(name)
const hide = addClass('hidden')
const show = removeClass('hidden')

// game

function Game() {
    const rooms = new Map()
    let currentRoom
    let selectedRoomId
    let state = 'initializing'

    function displayRoomInfos({ id, trapIsSet }) {
        const action = roomsData[id].action
        if (!action) {
            hideInformations()
            return
        }
        const { pa, name, effect } = action
        informationsEl.innerHTML = `<p><span class="action-pa">${pa}</span><span class="action-name">${name}</span><br/>${effect}</p>`
        show(informationsEl)
    }

    function hideInformations() {
        hide(informationsEl)
    }

    function moveTo(id) {
        rooms.forEach(room => room.leave())
        const room = rooms.get(id)
        room.enter()
        currentRoom = id
    }

    function onMoveActionRequested(id) {
        moveTo(id)
        if (state === 'starting point selection') {
            state = 'playing'
        }
    }

    function onPlayerIconClick(roomId) {
        if (state === 'starting point selection') {
            moveTo(roomId)
            state = 'playing'
            return
        }
        isVisible = !isVisible
        if (isVisible) {
            informationsEl.classList.remove('invisible')
            resetButton.classList.remove('invisible')
            setTrapButton.classList.remove('invisible')
            triggerTrapButton.classList.remove('invisible')
        } else {
            informationsEl.classList.add('invisible')
            resetButton.classList.add('invisible')
            setTrapButton.classList.add('invisible')
            triggerTrapButton.classList.add('invisible')
        }
        dispatch('game:visibility-toggled')
    }

    function onResetActionRequested() {
        state = 'starting point selection'
        hide(resetButton)
        reset()
    }

    function onRoomEntered() {
        show(resetButton)
    }

    function onRoomSelected({ id, trapIsSet }) {
        selectedRoomId = id
        if (state === 'starting point selection') {
            dispatch('starting-point:selected', id)
        }
        if (state === 'playing') {
            const room = rooms.get(id)
            trapIsSet
                ? show(triggerTrapButton)
                : hide(triggerTrapButton)
        }
    }

    function reset() {
        rooms.forEach(room => room.reset())
        hide(setTrapButton)
        state = 'starting point selection'
    }

    function setTrap() {
        if (state !== 'playing') return
        rooms.get(currentRoom).setTrap()
        hide(setTrapButton)
    }

    function start() {
        listen('room:entered', onRoomEntered)
        listen('room:selected', onRoomSelected)
        listen('move-action:requested', onMoveActionRequested)
        listen('reset-action:requested', onResetActionRequested)
        listen('set-trap-action:requested', setTrap)
        listen('trigger-trap-action:requested', triggerTrap)
        listen('player-icon:clicked', onPlayerIconClick)
        listen('room:selected', displayRoomInfos)

        resetButton.addEventListener('click', () => dispatch('reset-action:requested'))
        setTrapButton.addEventListener('click', () => dispatch('set-trap-action:requested'))
        triggerTrapButton.addEventListener('click', () =>
        dispatch('trigger-trap-action:requested'))

        Object.values(roomsData).forEach(roomData => {
            const room = Room(roomData)
            room.build(roomsEl)
            rooms.set(roomData.id, room)
        })

        state = 'starting point selection'
    }

    function triggerTrap() {
        if (state !== 'playing') return
        rooms.get(selectedRoomId).triggerTrap()
        hide(triggerTrapButton)
        show(setTrapButton)
    }

    return Object.freeze({ start })
}

function Room({ blueprint, icons, id, next, shootFrom }) {
    let roomEl
    let playerIconEl
    let moveIconEl
    let trapIconEl
    let shootIconEl
    let trapIsSet = false

    function build(manorEl) {
        roomEl = roomTemplate.cloneNode(true)
        roomEl.setAttribute('id', id)
        roomEl.style.top = `${blueprint.top}%`
        roomEl.style.left = `${blueprint.left}%`
        roomEl.style.width = `${blueprint.width}%`
        roomEl.style.height = `${blueprint.height}%`
        playerIconEl = roomEl.querySelector('.player-icon')
        playerIconEl.style.top = `${icons.step.top}%`
        playerIconEl.style.left = `${icons.step.left}%`
        moveIconEl = roomEl.querySelector('.move-icon')
        moveIconEl.style.top = `${icons.move.top}%`
        moveIconEl.style.left = `${icons.move.left}%`
        trapIconEl = roomEl.querySelector('.trap-icon')
        trapIconEl.style.top = `${icons.trap.top}%`
        trapIconEl.style.left = `${icons.trap.left}%`
        shootIconEl = roomEl.querySelector('.shoot-icon')
        shootIconEl.style.top = `${icons.shoot.top}%`
        shootIconEl.style.left = `${icons.shoot.left}%`
        playerIconEl.addEventListener('click', () => dispatch('player-icon:clicked', id))
        moveIconEl.addEventListener('click', () => dispatch('move-action:requested', id))
        roomEl.addEventListener('click', () => dispatch('room:selected', { id, trapIsSet }))
        listen('game:visibility-toggled', onVisibilityToggled)
        listen('room:entered', onAnyRoomEntered)
        listen('room:selected', onRoomSelected)
        listen('starting-point:selected', onStartingPointSelected)
        manorEl.appendChild(roomEl)
    }

    function enter() {
        show(playerIconEl)
        if (trapIsSet) {
            hide(setTrapButton)
            show(triggerTrapButton)
        } else {
            show(setTrapButton)
            hide(triggerTrapButton)
        }
        dispatch('room:entered', id)
    }

    function leave() {
        hide(playerIconEl)
    }

    function onAnyRoomEntered(roomId) {
        if (next.includes(roomId)) {
            show(moveIconEl)
        } else {
            hide(moveIconEl)
        }
        if (shootFrom.includes(roomId)) {
            show(shootIconEl)
        } else {
            hide(shootIconEl)
        }
    }

    function onRoomSelected({ id: roomId }) {
        if (roomId !== id) return
        trapIsSet
            ? show(triggerTrapButton)
            : hide(triggerTrapButton)
    }

    function onStartingPointSelected(targetId) {
        targetId === id ? show(playerIconEl) : hide(playerIconEl)
    }

    function onVisibilityToggled() {
        if (isVisible) {
            trapIconEl.classList.remove('invisible')
            moveIconEl.classList.remove('invisible')
            shootIconEl.classList.remove('invisible')
        } else {
            trapIconEl.classList.add('invisible')
            moveIconEl.classList.add('invisible')
            shootIconEl.classList.add('invisible')
        }
    }

    function reset() {
        trapIsSet = false
        hide(moveIconEl)
        hide(playerIconEl)
        hide(trapIconEl)
        hide(shootIconEl)
    }

    function setTrap() {
        trapIsSet = true
        show(trapIconEl)
    }

    function triggerTrap() {
        trapIsSet = false
        hide(trapIconEl)
    }

    return Object.freeze({
        build,
        enter,
        leave,
        reset,
        setTrap,
        triggerTrap,
    })
}

document.addEventListener('DOMContentLoaded', () => {
    const game = Game()
    game.start()
})
